@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.breadcrumbs {
    width: 100%;

    position: absolute;
    z-index: 10;

    // background-color: #ffffff;
}
.breadcrumbs__container {
    padding-top: 20px;
    padding-bottom: 20px;

    @media (min-width: $small-size) {
        padding-top: 36px;
        padding-bottom: 36px;
    }
}
.breadcrumbs__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.breadcrumbs__item {
    &:not(:last-child) {
        margin-right: 20px;
        padding-right: 24px;

        position: relative;

        @media (min-width: $small-size) {
            margin-right: 25px;
            padding-right: 28px;
        }

        &::after {
            content: '';

            width: 7px;
            height: 7px;
            border-top: 1px solid $color-grey;
            border-right: 1px solid $color-grey;

            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);

            @media (min-width: $small-size) {
                width: 9px;
                height: 9px;
            }
        }
    }
}
.breadcrumbs__text {
    display: inline-block;

    font-size: 14px;
    line-height: 160%;
    color: $color-grey;
    text-decoration: none;

    @media (min-width: $small-size) {
        font-size: 16px;
    }

    &_link {
        transition: color 300ms ease-in-out;

        @media (hover: hover) {
            &:hover {
                color: $color-laguna;
            }
        }
    }
}